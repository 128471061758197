module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":64,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers","options":{"offsetY":64}},{"resolve":"gatsby-remark-copy-linked-files","options":{"ignoreFileExtensions":[]}},{"resolve":"gatsby-remark-mermaid","options":{"mermaidOptions":{"themeCSS":"\n            .node rect,\n            .node circle {\n              stroke-width: 2px;\n              stroke: #3f20ba;\n              fill: #F4F6F8;\n            }\n            .node.secondary rect,\n            .node.secondary circle,\n            .node.tertiary rect,\n            .node.tertiary circle {\n              fill: white;\n            }\n            .node.secondary rect,\n            .node.secondary circle {\n              stroke: #f25cc1;\n            }\n            .node.tertiary rect,\n            .node.tertiary circle {\n              stroke: #41d9d3;\n            }\n          "}}},"gatsby-remark-prismjs-title",{"resolve":"gatsby-remark-prismjs","options":{"showLineNumbers":true}},"gatsby-remark-rewrite-relative-links",{"resolve":"gatsby-remark-check-links"}],"remarkPlugins":[[null,{"wrapperComponent":"MultiCodeBlock"}]],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/hossamsaraya/code/gqt/cryptogogos/cryptogogos-wiki"},
    },{
      plugin: require('../node_modules/gatsby-theme-apollo-docs/gatsby-browser.js'),
      options: {"plugins":[],"root":"/Users/hossamsaraya/code/gqt/cryptogogos/cryptogogos-wiki","siteName":"","githubRepo":"cryptogogos/cryptogogos-wiki","githubHost":"https://github.com/","twitterHandle":"https://twitter.com/cryptogogos","gaTrackingId":"XFB05CB8P4","description":"CryptoGOGOs Wiki & Knowledge Base","sidebarCategories":{"null":["index","universe","trading-cards","dist-and-economics","nft-staking","faq","glossary","memes"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
